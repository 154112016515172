<template>
 <v-card tile flat color=" " class="mt-10">
     <v-card-title class="px-0 zamu-heading form-header mb-10">Send us a Message!</v-card-title>
     <v-card-text>
           <v-alert v-if="notification !== ''"  dense text :type="`${actionClass}`" class="mt-5" >
                {{ notification }}
            </v-alert>
        <v-form  ref="form" v-model="valid" lazy-validation >
            <v-row no-gutters class="ma-0">
                <v-col cols="12" lg="5" md="5" sm="12" xs="12" class="mr-13">
                    <label for="">Name</label>
                    <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    required
                    outlined
                    dense
                    ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12" xs="12" >
                    <label for="">E-mail</label>
                    <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    required
                    outlined
                    dense
                    ></v-text-field>
                </v-col>
                <v-col cols="12" lg="12" md="12" sm="12" xs="12" >
                    <label for="">Subject</label>
                    <v-text-field
                    v-model="subject"
                    :rules="subjectRules"
                    required
                    outlined
                    dense
                    ></v-text-field>
                </v-col>
                <v-col cols="12" lg="12" md="12" sm="12" xs="12" >
                    <label for="">Message</label>
                    <v-textarea
                    v-model="message"
                    outlined
                    :rules="messageRules"
                    required
                    ></v-textarea>
                </v-col>
                  
                <!-- <v-col cols="12" lg="12" md="12" sm="12" xs="12" >
                

                    <v-checkbox
                    v-model="checkbox"
                    label="Send a copy to my e-mail address ?"
                    ></v-checkbox>
                </v-col> -->

                    <v-btn
                    :disabled="!valid || loading"
                    :color="`${zamuYellow3}`"
                    class="mr-4 zamu-sub-heading "
                    large
                    @click="validate"
                    >
                    Send Message
                     <v-progress-circular
                        indeterminate
                        color="primary"
                        class="ml-4"
                        :size="20"
                        v-if="loading"
                        ></v-progress-circular>
                    </v-btn>

            </v-row>

        </v-form>
     </v-card-text>

 </v-card>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name: 'ContactForm',
    mixins: [colorMixin, coreMixin],

    data () {
        return {
            valid: false,
            name: '',
            email: '',
            select: null,
            subject: '',
            message: '',
            checkbox: false,
            loading: false,
            notification: '',
            actionClass: '',
    };
    },

    methods: {
     async validate () {
        const formvalid = this.$refs.form.validate();
        if(!formvalid) {
            return;
        }
               this.loading = true;

               const payload = {
                    name: this.name,
                    email: this.email,
                    subject: this.subject,
                    message: this.message
                };
                const fullPayload = {
                    app: this.apiUrl,
                    params: payload,
                    endpoint: 'api/contacts',
                };
                  try {
                    const res = await this.axiosPostRequest(fullPayload);
                    this.loading = false;
                    this.notification = res.status === 200 ? 'Message saved successful' : res.data.message;
                    this.actionClass = res.status === 200 ? 'success' : 'error';

                    if(res.status === 200 ) {
                        this.reset()
                    }

                } catch (error) {
                    this.notification = 'Message failed to save. Contact the admin';
                    this.actionClass = 'error';
                    this.loading = false;
                    return error.response;
                }

      },
      reset () {
        this.$refs.form.reset()
      },
    },

}
</script>

<style>

</style>